import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import DraggableIcon from "../left-sidebar/DraggableIcon";

function DropZoneFrameRow(props) {
  return (
    <Droppable
      droppableId={`frame-${props.frameId}-${props.rowId}`}
      direction="horizontal"
      key={`frame-${props.frameId}-${props.rowId}`}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{
            width: props.columns * 76,
            height: props.rowId === 3 ? 153 : 76,
            display: "flex",
            background: "#f5f5f5aa",
            border: "1px solid gray",
            borderBottom: props.rowId !== 3 ? null : "1px solid gray",
            background: snapshot.isDraggingOver ? "#B9D9EBaa" : "none",
          }}
          {...provided.droppableProps}
        >
          {props.row_data.map((DragItem, index) => {
            return (
              <Draggable
                key={`${props.frameId}-${props.rowId}-${index}`}
                draggableId={`${props.frameId}-${props.rowId}-${index}`}
                index={index}
              >
                {(provided, snapshot) =>
                  typeof DragItem === "object" ? (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <div {...provided.dragHandleProps}></div>
                      <Droppable
                        droppableId={`frame-${props.frameId}-${props.rowId}-${index}`}
                        direction="vertical"
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={{
                              width: 76,
                              height: 151,
                              background: snapshot.isDraggingOver
                                ? "#fcbacbaa"
                                : "none",
                            }}
                            {...provided.droppableProps}
                          >
                            {DragItem.map((NestedDragItem, NestedItemIndex) => {
                              return (
                                <Draggable
                                  key={`${props.frameId}-${props.rowId}-${index}-${NestedItemIndex}`}
                                  draggableId={`${props.frameId}-${props.rowId}-${index}-${NestedItemIndex}`}
                                  index={NestedItemIndex}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <DraggableIcon
                                          blur={false}
                                          id={NestedDragItem}
                                          isDragging={snapshot.isDragging}
                                        />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  ) : (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="drag"
                    >
                      <DraggableIcon
                        blur={false}
                        id={DragItem}
                        isDragging={snapshot.isDragging}
                      />
                    </div>
                  )
                }
              </Draggable>
            );
          })}
        </div>
      )}
    </Droppable>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    row_data:
      state.main.frames[state.main.activeFrame].data[`row${ownProps.rowId}`]
        .data,
    row_show:
      state.main.frames[state.main.activeFrame].data[`row${ownProps.rowId}`]
        .show,
  };
};

export default connect(mapStateToProps, null)(DropZoneFrameRow);
