import React, { useState } from "react";
import { caretDownOutline, addOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { connect } from "react-redux";
import {
  ADD_NEW_EMPTY_FRAME,
  DELETE_FRAME,
  SWITCH_FRAME,
} from "redux/main/types.main";

function Footer(props) {
  const [add, setAdd] = useState(false);

  return (
    <div className="plushicon" style={{ width: "100%" }}>
      <div className="horizontal-scroll">
        <ul className="list">
          {[...Array(props.frames).keys()].map((count) => (
            <li key={count}>
              <div
                className="btn-add-list"
                style={{
                  background:
                    props.activeFrame === count ? "#26de8155" : "none",
                  color: props.activeFrame === count ? "#20bf6b" : "black",
                }}
              >
                <span
                  onClick={() => {
                    props.switchFrame(count);
                  }}
                  style={{
                    display: "block",
                    padding: 7,
                  }}
                >
                  Frame {count + 1}
                </span>
                {props.frames === 1 ? null : (
                  <ion-icon
                    onClick={() => {
                      props.deleteFrame(count);
                    }}
                    name="close"
                    style={{
                      background:
                        props.activeFrame === count ? "red" : "#ff525244",
                      padding: 3,
                      color: props.activeFrame === count ? "white" : "red",
                      marginRight: 5,
                      fontSize: 10,
                      fontWeight: "bold",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  ></ion-icon>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <button
        style={{ display: props.frames >= 5 ? "none" : "inherit" }}
        className="addp"
        onBlur={() => {
          setAdd(false);
        }}
      >
        <span
          onClick={() => {
            setAdd(true);
          }}
        >
          <IonIcon icon={addOutline}></IonIcon>
        </span>
        <li className="list" style={{ zIndex: 50 }}>
          <ul
            className="item-drop"
            style={{ display: add ? "block" : "none", textAlign: "left" }}
          >
            <li
              onClick={() => {
                setAdd(false);
                props.addNewEmptyFrame(false);
              }}
            >
              From Blank
            </li>
            <li
              onClick={() => {
                setAdd(false);
                props.addNewEmptyFrame(true);
              }}
            >
              From Current Frame
            </li>
          </ul>
        </li>
      </button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    frames: state.main.frames.length,
    activeFrame: state.main.activeFrame,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewEmptyFrame: (fromCurrent) => {
      dispatch({ type: ADD_NEW_EMPTY_FRAME, payload: fromCurrent });
    },
    deleteFrame: (frameId) => {
      dispatch({ type: DELETE_FRAME, payload: frameId });
    },
    switchFrame: (frameId) => {
      dispatch({ type: SWITCH_FRAME, payload: frameId });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
