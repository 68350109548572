import { IonButton } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { ADD_NEW_COLUMN, REMOVE_EMPTY_COLUMN } from "redux/main/types.main";

function ColumnControls(props) {
  return (
    <div>
      <p style={{ marginTop: 0 }}>Bay Options</p>
      <IonButton
        color="tertiary"
        shape="round"
        expand="full"
        className="mb-3"
        disabled={props.columns >= 8}
        onClick={props.addNewColumn}
      >
        Add Bay
      </IonButton>
      <IonButton
        color="tertiary"
        shape="round"
        expand="full"
        className="mb-3"
        disabled={props.columns <= 1}
        onClick={props.removeEmptyColumn}
      >
        Remove Bay
      </IonButton>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNewColumn: () => {
      dispatch({ type: ADD_NEW_COLUMN });
    },
    removeEmptyColumn: () => {
      dispatch({ type: REMOVE_EMPTY_COLUMN });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    columns: state.main.frames[state.main.activeFrame].columns,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnControls);
