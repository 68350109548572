import { IonButton, IonItem, IonSelect } from "@ionic/react";
import React, { useState } from "react";
import { connect } from "react-redux";
import { TOGGLE_ROW_SHOW } from "redux/main/types.main";

function RowControls(props) {
  //   console.log(Object.values(props.show));
  const hiddenRowCount = Object.values(props.show).reduce((acc, show) => {
    return !show ? acc + 1 : acc;
  }, 0);

  const [hide, setHide] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setHide(value);
  };

  const handleUpdate = (type = "show") => {
    if (type === "show") {
      if (props.show.row2 && props.show.row1) {
        return;
      }
      let row = props.show.row2 ? "row1" : "row2";
      props.toggleRowShow({ row, type });
    } else if (type == "hide") {
      if (!hide) {
        return;
      }
      if (hide === "row1") {
        setHide("row2");
      }
      props.toggleRowShow({ row: hide, type });
    }
  };

  return (
    <>
      <p style={{ marginTop: 0 }}>Row controls</p>
      <IonButton
        color="primary"
        shape="round"
        expand="full"
        className="mb-3"
        disabled={hiddenRowCount < 1}
        onClick={() => {
          handleUpdate();
        }}
      >
        Add new Row
      </IonButton>
      <IonItem className="mb-3 border-64">
        <IonSelect
          placeholder={hiddenRowCount < 2 ? "Select Row" : "Nothing To Select"}
          interface="popover"
          onIonChange={handleChange}
          name="hide"
          value={hide}
        >
          {props.show.row1 ? (
            <ion-select-option value="row1">Top Row</ion-select-option>
          ) : null}
          {props.show.row2 && !props.show.row1 ? (
            <ion-select-option value="row2">Top Row</ion-select-option>
          ) : null}

          {props.show.row1 && props.show.row2 ? (
            <ion-select-option value="row2">Middle Row</ion-select-option>
          ) : null}
        </IonSelect>
        <IonButton
          color="primary"
          slot="end"
          style={{ margin: 0 }}
          onClick={() => {
            handleUpdate("hide");
          }}
        >
          hide
        </IonButton>
      </IonItem>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    show: Object.keys(state.main.frames[state.main.activeFrame].data).reduce(
      (acc, row) => {
        acc[row] = state.main.frames[state.main.activeFrame].data[row].show;
        return acc;
      },
      {}
    ),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleRowShow: (payload) => {
      dispatch({ type: TOGGLE_ROW_SHOW, payload });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RowControls);
