// export const CHANGE_ITEM_POSITION = "CHANGE_ITEM_POSITION";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const ADD_NEW_EMPTY_FRAME = "ADD_NEW_EMPTY_FRAME";
export const DELETE_FRAME = "DELETE_FRAME";
export const SWITCH_FRAME = "SWITCH_FRAME";

export const ADD_NEW_DRAG_ITEM = "ADD_NEW_DRAG_ITEM";
export const CHANGE_DRAG_ITEM_POSITION = "CHANGE_DRAG_ITEM_POSITION";
export const DELETE_DRAG_ITEM = "DELETE_DRAG_ITEM";

// Row Manipulation
export const TOGGLE_ROW_SHOW = "TOGGLE_ROW_SHOW";
export const TOGGLE_UPDATE_DROPZONE = "TOGGLE_UPDATE_DROPZONE";
export const ADD_DROPZONE_COLUMN = "ADD_DROPZONE_COLUMN";
export const REMOVE_DROPZONE_COLUMN = "REMOVE_DROPZONE_COLUMN";

export const ADD_NEW_COLUMN = "ADD_NEW_COLUMN";
export const REMOVE_EMPTY_COLUMN = "REMOVE_EMPTY_COLUMN";

export const TOGGLE_PREVIEW = "TOGGLE_PREVIEW";

export const SET_USER_DATA = "SET_USER_DATA";

export const ADD_NESTED_DRAG_ITEM = "ADD_NESTED_DRAG_ITEM";
export const CHANGE_POSITION_NESTED_DRAG_ITEM =
  "CHANGE_POSITION_NESTED_DRAG_ITEM";
