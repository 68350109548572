import moniterImage from "../../images/Moniter.png";
import comboAImage from "../../images/combo-A.png";
import comboBImage from "../../images/combo-B.png";
import faderAImage from "../../images/Fader-A.png";
import faderBImage from "../../images/Fader-B.png";
import faderBlankImage from "../../images/Blink.png";
import rotaryImage from "../../images/rotary.png";
import keyImage from "../../images/key.png";
import palateImage from "../../images/blind-plate-half.png";
import combiImage from "../../images/combo.png";
import VirtualImage from "../../images/virtual-extension.png";

export default {
  moniter: {
    image: moniterImage,
    height: 150,
    width: 75,
  },
  combo_a: {
    image: comboAImage,
    height: 150,
    width: 75,
  },
  combo_b: {
    image: comboBImage,
    height: 150,
    width: 75,
  },
  fader_a: {
    image: faderAImage,
    height: 150,
    width: 75,
  },
  fader_b: {
    image: faderBImage,
    height: 150,
    width: 75,
  },
  fader_blank: {
    image: faderBlankImage,
    height: 150,
    width: 75,
  },
  rotary: {
    image: rotaryImage,
    height: 75,
    width: 75,
  },
  key: {
    image: keyImage,
    height: 75,
    width: 75,
  },
  palate: {
    image: palateImage,
    height: 75,
    width: 75,
  },
  combi: {
    image: combiImage,
    height: 75,
    width: 75,
  },
  virtual: {
    image: VirtualImage,
    width: 150,
    height: 75,
  },
};
