import React, { createRef } from "react";
import { IonButton } from "@ionic/react";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import mainData from "components/home/main.data";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "95vh",
  bgcolor: "#ffffff",
  border: "2px solid #000",
  boxShadow: 24,
  maxHeight: "100vh",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
};

class PreviewImage extends React.Component {
  canvasRef = createRef(null);

  drawImage = (imgId, context, x, y, ratio) => {
    const img = new Image();
    const imgData = mainData[imgId];
    img.src = imgData.image;

    img.width = imgData.width;
    img.height = imgData.height;

    context.drawImage(img, x, y, imgData.width * ratio, imgData.height * ratio);
  };

  canvasImageImplementation = (context, ratio = 1) => {
    Object.keys(this.props.frame.data)
      .filter((item) => this.props.frame.data[item].show)
      .forEach((row, rowIndex) => {
        this.props.frame.data[row].data.forEach((imgId, imageIndex) => {
          if (typeof imgId === "object") {
            imgId.forEach((NestedImgId, NestedImgIndex) => {
              const x =
                imageIndex *
                (NestedImgId === "virtual" ? 150 * ratio : 75 * ratio);
              const y = rowIndex * 75 * ratio + NestedImgIndex * 75 * ratio;
              this.drawImage(NestedImgId, context, x, y, ratio);
            });
            return;
          }

          const x =
            imageIndex * (imgId === "virtual" ? 150 * ratio : 75 * ratio);
          const y = rowIndex * 75 * ratio;

          this.drawImage(imgId, context, x, y, ratio);
        });
      });
  };

  download = () => {
    const canvasEl = document.createElement("canvas");
    canvasEl.id = "download-image-lawo-final";
    canvasEl.height = this.canvasHeight * 20;
    canvasEl.width = this.canvasWidth * 20;

    const context = canvasEl.getContext("2d");
    this.canvasImageImplementation(context, 20);
    var lnk = document.createElement("a"),
      e;

    lnk.download = "diamond";

    lnk.href = canvasEl.toDataURL("image/png;base64");

    /// create a "fake" click-event to trigger the download
    if (document.createEvent) {
      e = document.createEvent("MouseEvents");
      e.initMouseEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );

      lnk.dispatchEvent(e);
    } else if (lnk.fireEvent) {
      lnk.fireEvent("onclick");
    }
  };

  componentDidMount = () => {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d");

    this.canvasImageImplementation(context);
    this.canvasWidth = 75 * this.props.frame.columns;
    this.canvasHeight =
      75 *
      Object.keys(this.props.frame.data).reduce((acc, item) => {
        if (item === "row3") {
          acc = this.props.frame.data[item].show ? acc + 2 : acc;
        } else {
          acc = this.props.frame.data[item].show ? acc + 1 : acc;
        }

        return acc;
      }, 0);
  };

  render() {
    return (
      <Box sx={style}>
        <div
          style={{
            backgroundColor: "#eee",
            borderTop: "1px solid #eeeeee",
            textAlign: "center",
            position: "sticky",
            top: 0,
            textTransform: "uppercase",
            padding: ".5em",
          }}
        >
          <ion-text>
            <h1 style={{ margin: 0 }}>Image Preview</h1>
          </ion-text>
        </div>
        <div
          style={{
            flex: 1,
            minHeight: 300,
            minWidth: 300,
            textAlign: "center",
            overflow: "auto",
          }}
        >
          <canvas
            id="preview-image"
            style={{ padding: 10 }}
            width={75 * this.props.frame.columns}
            height={
              75 *
              Object.keys(this.props.frame.data).reduce((acc, item) => {
                if (item === "row3") {
                  acc = this.props.frame.data[item].show ? acc + 2 : acc;
                } else {
                  acc = this.props.frame.data[item].show ? acc + 1 : acc;
                }

                return acc;
              }, 0)
            }
            ref={this.canvasRef}
          ></canvas>
        </div>
        <div
          style={{
            backgroundColor: "#eee",
            borderTop: "1px solid #eeeeee",
            textAlign: "center",
            position: "sticky",
            bottom: 0,
          }}
        >
          <IonButton onClick={this.download}>Download the Image</IonButton>
        </div>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    frame: state.main.frames[state.main.activeFrame],
  };
};

export default connect(mapStateToProps, null)(PreviewImage);
