import React from "react";
import { connect } from "react-redux";
import DraggableIcon from "./DraggableIcon";
import { Droppable, Draggable } from "react-beautiful-dnd";

class DraggableGroup extends React.Component {
  constructor(...everything) {
    super(...everything);
    this.state = { ...this.props };
  }

  render() {
    return (
      <div className="mb-3">
        <h5 className="p-lg-10" style={{padding:"0 10px"}}>{this.props.heading}</h5>

        <Droppable
          isDropDisabled
          droppableId={this.props.heading}
          direction="horizontal"
        >
          {(provided, snapshot) => (
            <div
              className="d-flex p-lg-10"
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
              placeholder={provided.placeholder}
            >
              {this.props.images.map((image, index) => {
                return (
                  <Draggable
                    key={index}
                    draggableId={`sidebar-${image.id}`}
                    index={index}
                    isDragDisabled={
                      (!this.props.isStarted &&
                        !(
                          this.props.heading === "Central Modules" ||
                          this.props.heading === "Fader Modules"
                        )) ||
                      (this.props.heading === "Central Modules" &&
                        this.props.alreadyHaveCentral)
                    }
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // style={getItemStyle(
                        //   snapshot.isDragging,
                        //   provided.draggableProps.style,
                        //   image.width
                        // )}
                        alt=""
                        className="moniter"
                      >
                        <DraggableIcon
                          blur={
                            (!this.props.isStarted &&
                              !(
                                this.props.heading === "Central Modules" ||
                                this.props.heading === "Fader Modules"
                              )) ||
                            (this.props.heading === "Central Modules" &&
                              this.props.alreadyHaveCentral)
                          }
                          {...image}
                          isDragging={snapshot.isDragging}
                        />
                        {image.heading ? <h6>{image.heading}</h6> : null}
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isStarted: Object.values(
      state.main.frames[state.main.activeFrame].data
    ).reduce((acc, { data }) => {
      if (data.length > 0) {
        return true;
      }
      return acc;
    }, false),

    alreadyHaveCentral: state.main.frames[
      state.main.activeFrame
    ].data.row3.data.reduce(
      (acc, item) =>
        acc ? acc : ["moniter", "combo_a", "combo_b"].includes(item),
      false
    ),
  };
};

export default connect(mapStateToProps)(DraggableGroup);
