import React from "react";
import ColumnControls from "./ColumnControls";
import RowControls from "./RowControls";

export default function NavGroup() {
  return (
    <div className="ismobile-5">
      <RowControls />
      <ColumnControls />
    </div>
  );
}
