import React from "react";
import { connect } from "react-redux";
import DraggableData from "components/home/main.data";

function DraggableIcon(props) {
  return (
    <>
      <div
        style={{
          opacity: props.blur ? 0.7 : 1,
          height: DraggableData[props.id].height,
          width: DraggableData[props.id].width,
        }}
        className={DraggableData[props.id].className}
      >
        <img
          src={DraggableData[props.id].image}
          alt=""
          style={{
            height: DraggableData[props.id].height,
            width: DraggableData[props.id].width,
          }}
        />
      </div>
    </>
  );
}

export default connect(null, null)(DraggableIcon);
