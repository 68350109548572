export const addNewEmptyFrame = (state, fromCurrent = false) => {
  if (fromCurrent) {
    state.frames.push(
      JSON.parse(JSON.stringify(state.frames[state.activeFrame]))
    );
  } else {
    state.frames.push({
      data: {
        row1: {
          show: false,
          data: []
        },
        row2: {
          show: true,
          data: []
        },
        row3: {
          show: true,
          data: []
        },
      },
      columns: 2,
    });
  }
  return switchFrame(state, state.frames.length - 1);
};

export const deleteFrame = (state, frameId) => {
  state.frames.splice(frameId, 1);

  return switchFrame(state, state.frames.length - 1);
};

export const switchFrame = (state, frameId) => {
  state.activeFrame = frameId;
  state.updateDropzone = true;
  return state;
};

export const addNewDragItem = (
  state, {
    destinationRowId,
    destinationFrameId,
    dropIndex,
    draggableId
  }
) => {
  const row = state.frames[destinationFrameId].data[`row${destinationRowId}`];
  const frame = state.frames[destinationFrameId];

  const smallCheck = ["rotary", "key", "combi", "palate"];

  if (smallCheck.includes(draggableId) && destinationRowId == 3) {
    row.data.splice(dropIndex, 0, [draggableId]);
  } else {
    row.data.splice(dropIndex, 0, draggableId);
    if (draggableId === "virtual") {
      frame.columns =
        Math.floor(frame.columns / 2) < row.data.length ?
        row.data.length * 2 :
        frame.columns;
    }
  }
  state.updateDropzone = true;
  return state;
};

export const changeDragItemPosition = (
  state, {
    sourceRowId,
    destinationRowId,
    sourceFrameId,
    destinationFrameId,
    dropIndex,
    dragIndex,
  }
) => {
  const row = state.frames[destinationFrameId].data[`row${destinationRowId}`];
  const frame = state.frames[destinationFrameId];

  const [removed] = state.frames[sourceFrameId].data[
    `row${sourceRowId}`
  ].data.splice(dragIndex, 1);

  const checkForNested =
    destinationRowId == 3 && ["rotary", "key", "combi", "palate"].includes(removed);

  row.data.splice(dropIndex, 0, checkForNested ? [removed] : removed);

  if (row.data[dropIndex] === "virtual") {
    frame.columns =
      Math.floor(frame.columns / 2) < row.data.length ?
      row.data.length * 2 :
      frame.columns;
  }

  state.updateDropzone = true;

  return state;
};

export const toggleRowShow = (state, {
  row,
  type
}) => {
  if (type === "show") {
    state.frames[state.activeFrame].data[row].show = true;
  } else if (type === "hide") {
    if (row === "row2" && state.frames[state.activeFrame].data.row1.show) {
      state.frames[state.activeFrame].data.row2.data = [
        ...state.frames[state.activeFrame].data.row1.data,
      ];
      row = "row1";
    }
    state.frames[state.activeFrame].data[row].data = [];
    state.frames[state.activeFrame].data[row].show = false;
  }

  state.updateDropzone = true;

  return state;
};

export const addNewColumn = (state) => {
  if (state.frames[state.activeFrame].columns < 8) {
    state.frames[state.activeFrame].columns++;
  }
  state.updateDropzone = true;
  return state;
};

export const removeEmptyColumn = (state) => {
  state.frames[state.activeFrame].columns = Object.values(
    state.frames[state.activeFrame].data
  ).reduce((acc, row) => {
    if (row.data.includes("virtual")) {
      return acc > row.data.length * 2 ? acc : row.data.length * 2;
    }
    return acc > row.data.length ? acc : row.data.length;
  }, 0);

  state.frames[state.activeFrame].columns =
    state.frames[state.activeFrame].columns || 1;

  state.updateDropzone = true;
  return state;
};

export const deleteDragItem = (state, {
  rowId,
  dragIndex,
  nestedId
}) => {
  let row = state.frames[state.activeFrame].data[`row${rowId}`];
  if (nestedId === null) {
    row.data.splice(dragIndex, 1);
  } else {
    row.data[nestedId].splice(dragIndex, 1);

    row.data = row.data.filter((item) => {
      return !(typeof item === "object" && item.length === 0);
    });
  }
  state.updateDropzone = true;
  return state;
};

export const addNestedDragItem = (
  state, {
    destinationFrameId,
    destinationRowId,
    destinationNestedId,
    destinationIndexId,
    draggableId,
  }
) => {
  const NestedItems =
    state.frames[destinationFrameId].data[`row${destinationRowId}`].data[
      destinationNestedId
    ];
  const frame = state.frames[destinationFrameId];

  // Return if Nested Items are full
  if (NestedItems.length === 2) {
    return state;
  }

  NestedItems.splice(destinationIndexId, 0, draggableId);
  return state;
};

export const changePositionNestedDragItem = (
  state, {
    destinationFrameId,
    destinationRowId,
    destinationNestedId = null,
    destinationIndexId,
    sourceFrameId,
    sourceRowId,
    sourceNestedId = null,
    sourceIndexId,
  }
) => {
  // destination Frame and Row Data
  // const destinationFrame = state.frames[destinationFrameId];
  const destinationRow =
    state.frames[destinationFrameId].data[`row${destinationRowId}`];

  // source Frame and Row Data
  // const sourceFrame = state.frames[sourceFrameId];
  const sourceRow = state.frames[sourceFrameId].data[`row${sourceRowId}`];

  // From other Rows To Nested within Frame
  if (sourceNestedId === null && destinationNestedId !== null) {
    const smallElements = ["rotary", "key", "combi", "palate"]

    if (!smallElements.includes(sourceRow.data[sourceIndexId])) {
      return state;
    }

    const [removed] = sourceRow.data.splice(sourceIndexId, 1);

    destinationRow.data[destinationNestedId].splice(
      destinationIndexId,
      0,
      removed
    );
  }

  // From Nested To other Row within Frame
  if (sourceNestedId !== null && destinationNestedId === null) {

    // check column length
    if (destinationRow.data.length === state.frames[destinationFrameId].columns) {
      return state;
    }
    // Check if it have virtual Elements
    if (destinationRow.data.includes("virtual")) {
      return state;
    }

    const [removed] = sourceRow.data[sourceNestedId].splice(sourceIndexId, 1);
    if (
      sourceRow.data[sourceNestedId].length === 0 &&
      typeof sourceRow.data[sourceNestedId] === "object"
    ) {
      sourceRow.data.splice(sourceNestedId, 1);
    }

    destinationRow.data.splice(destinationIndexId, 0, destinationRowId != 3 ? removed : [removed]);
  }

  // From Nested To Another Nested Group within Frame
  if (sourceNestedId !== null && destinationNestedId !== null) {
    const [removed] = sourceRow.data[sourceNestedId].splice(sourceIndexId, 1);

    destinationRow.data[destinationNestedId].splice(
      destinationIndexId,
      0,
      removed
    );
  }
  state.updateDropzone = true;
  return state;
};