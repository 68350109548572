import moniter from "../../images/Moniter.png";
import comboA from "../../images/combo-A.png";
import comboB from "../../images/combo-B.png";
import faderA from "../../images/Fader-A.png";
import faderB from "../../images/Fader-B.png";
import faderBlank from "../../images/Blink.png";
import rotary from "../../images/rotary.png";
import key from "../../images/key.png";
import palate from "../../images/blind-plate-half.png";
import combi from "../../images/combo.png";
import Virtual from "../../images/virtual-extension.png";

export default [
  {
    heading: "Central Modules",
    images: [
      {
        src: moniter,
        heading: "Central",
        id: "moniter",
      },
      {
        src: comboA,
        heading: "Combo A",
        id: "combo_a",
      },
      {
        src: comboB,
        heading: "Combo B",
        id: "combo_b",
      },
    ],
  },
  {
    heading: "Fader Modules",
    images: [
      { src: faderA, heading: "Fader A", id: "fader_a" },
      {
        src: faderB,
        heading: "Fader B",
        id: "fader_b",
      },
      {
        src: faderBlank,
        heading: "Blank",
        id: "fader_blank",
      },
    ],
  },
  {
    heading: "Extension Modules",
    images: [
      { src: rotary, heading: "Rotary", id: "rotary" },
      { src: key, heading: "Key", id: "key" },
      { src: combi, heading: "Combi", id: "combi" },
      { src: palate, heading: "Blank", id: "palate" },
    ],
  },
  {
    heading: "Virtual Extension",
    images: [
      {
        src: Virtual,
        id: "virtual",
      },
    ],
  },
];
