import React from "react";
import { connect } from "react-redux";
import DropZoneFrame from "./DropZoneFrame";
import Footer from "./Footer";

class DropZone extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "calc(100vh - 70px)",
          background:
            "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDUgTCAyMCA1IE0gNSAwIEwgNSAyMCBNIDAgMTAgTCAyMCAxMCBNIDEwIDAgTCAxMCAyMCBNIDAgMTUgTCAyMCAxNSBNIDE1IDAgTCAxNSAyMCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDBkMGQwIiBvcGFjaXR5PSIwLjIiIHN0cm9rZS13aWR0aD0iMSIvPjxwYXRoIGQ9Ik0gMjAgMCBMIDAgMCAwIDIwIiBmaWxsPSJub25lIiBzdHJva2U9IiNkMGQwZDAiIHN0cm9rZS13aWR0aD0iMSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==)",
        }}
      >
        <h5 className="m-0 p-15 bg-white" style={{ width: "100%" }}>
          Frame {this.props.activeFrame + 1}
        </h5>

        <DropZoneFrame
          frame={this.props.frame}
          frameId={this.props.activeFrame}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    frame: state.main.frames[state.main.activeFrame],
    activeFrame: state.main.activeFrame,
  };
};

export default connect(mapStateToProps, null)(DropZone);
