import React, { Component } from "react";
import { IonHeader, IonToolbar, IonTitle, IonIcon } from "@ionic/react";
import {
  arrowRedoOutline,
  cloudDownloadOutline,
  menuOutline,
} from "ionicons/icons";
import logo from "../../images/logo.svg";
import { connect } from "react-redux";
import { TOGGLE_SIDEBAR, TOGGLE_PREVIEW } from "redux/main/types.main";
import { Snackbar } from "@mui/material";

class TopBar extends Component {
  state = {
    msg_show: false,
  };

  handleMessage = () => {
    this.setState((state) => ({ ...state, msg_show: !this.state.msg_show }));
  };

  checkDownload = () => {
    const virtualElement = "virtual";

    const checkDownload = Object.keys(this.props.frame.data).reduce(
      (acc, row) => {
        row = this.props.frame.data[row];
        if (acc && row.show) {
          let length = row.data.length;

          if (row.data.includes(virtualElement)) {
            length = row.data.length * 2;
          }
          return this.props.frame.columns === length;
        }
        return acc;
      },
      true
    );

    if (checkDownload) {
      this.props.handleModalOpen();
      return;
    }
    this.setState({ msg_show: true });
  };

  render() {
    return (
      <>
        <Snackbar
          open={this.state.msg_show}
          autoHideDuration={3000}
          onClose={this.handleMessage}
          message="Please ensure to complete it!"
          color="primary"
        />
        <IonHeader>
          <IonToolbar>
            <IonTitle slot="start" style={{paddingLeft:"10px"}} className="mobile-space p-0">
              <div className="logo">
                <img src={logo} alt="" style={{ width: "45px" }} /> Diamond
                Designer
              </div>
            </IonTitle>

            <div slot="end" className="button-share">
              <div className="share-btn d-none">
                <a href="#">
                  <IonIcon slot="icon-only" icon={arrowRedoOutline} />
                  <div className="d-mobile"> Share</div>
                </a>
              </div>
              <div className="share-btn">
                <a href="#" onClick={this.checkDownload}>
                  <IonIcon slot="icon-only" icon={cloudDownloadOutline} />
                  <div className="d-mobile"> Download</div>
                </a>
              </div>
              <div className="share-btn show-menu" onClick={this.props.toggleSidebar}>
                <IonIcon slot="icon-only" icon={menuOutline}></IonIcon>
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => {
      dispatch({ type: TOGGLE_SIDEBAR });
    },
    handleModalOpen: () => {
      dispatch({ type: TOGGLE_PREVIEW });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    frame: state.main.frames[state.main.activeFrame],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
