import React, { Component } from "react";
import { IonPage, IonContent } from "@ionic/react";
import CloseIcon from "@mui/icons-material/Close";

import TopBar from "components/includes/TopBar";
import Platform from "components/home/Platform";
import { Button, IconButton, Modal, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";

import { TOGGLE_PREVIEW } from "redux/main/types.main";
import { connect } from "react-redux";

import PreviewImage from "components/home/PreviewImage";

class Home extends Component {
  // state = {
  //   showInstallable: true,
  //   deferredPrompt: null,
  // };

  // handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   this.setState({ showInstallable: false });
  // };

  // handleInstall = async () => {
  //   this.setState({ showInstallable: false });
  //   this.state.deferredPrompt.prompt();
  //   const { outcome } = await this.state.deferredPrompt.userChoice;
  //   this.setState({ deferredPrompt: null });
  // };

  componentDidMount() {
    // window.addEventListener("beforeinstallprompt", (e) => {
    //   e.preventDefault();
    //   this.setState({ deferredPrompt: e, showInstallable: true });
    // });

    // window.addEventListener("appinstalled", () => {
    //   this.setState({ deferredPrompt: null, showInstallable: false });
    // });
  }
  render() {
    // const action = (

    // );

    return (
      <IonPage>
        {/* <Snackbar
          open={this.state.showInstallable}
          onClose={this.handleClose}
          message="INSTALL ON DEVICE"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          // action={action}
        >
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              cursor: "pointer",
              textTransform: "uppercase",
              fontWeight: "bold",
              bgcolor: "green",
              color: "white",
            }}
            variant="outlined"
          >
            <div onClick={this.handleInstall}>Install It On Your Device</div>
            <IconButton
              sx={{ ml: 1 }}
              size="small"
              aria-label="close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Paper>
        </Snackbar> */}

        <TopBar />

        <IonContent>
          <Platform {...this.state} />
          <Modal
            open={this.props.openModal}
            onClose={this.props.handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <PreviewImage />
          </Modal>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openModal: state.main.previewImage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleModalClose: () => {
      dispatch({ type: TOGGLE_PREVIEW });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
