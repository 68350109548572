import {
  addNewEmptyFrame,
  addNewDragItem,
  changeDragItemPosition,
  toggleRowShow,
  addNewColumn,
  removeEmptyColumn,
  deleteDragItem,
  deleteFrame,
  switchFrame,
  addNestedDragItem,
  changePositionNestedDragItem,
} from "./actions.main";
import {
  ADD_NEW_COLUMN,
  ADD_NEW_DRAG_ITEM,
  ADD_NEW_EMPTY_FRAME,
  CHANGE_DRAG_ITEM_POSITION,
  DELETE_DRAG_ITEM,
  REMOVE_EMPTY_COLUMN,
  TOGGLE_ROW_SHOW,
  TOGGLE_UPDATE_DROPZONE,
  TOGGLE_SIDEBAR,
  DELETE_FRAME,
  SWITCH_FRAME,
  TOGGLE_PREVIEW,
  SET_USER_DATA,
  ADD_NESTED_DRAG_ITEM,
  CHANGE_POSITION_NESTED_DRAG_ITEM,
} from "./types.main";
/**
 * Frame Interface
 * {DragItems:[], }
 */
// The initial state of the App
let initialState;

if (localStorage.getItem("user_data")) {
  initialState = JSON.parse(localStorage.getItem("user_data"));
} else {
  initialState = {
    frames: [{
      data: {
        row1: {
          show: false,
          data: []
        },
        row2: {
          show: true,
          data: []
        },
        row3: {
          show: true,
          data: []
        },
      },
      columns: 2,
    }, ],
    activeFrame: 0,
    updateDropzone: false,
    isSidebarOpen: false,
    previewImage: false,
  };
}

/* eslint-disable default-case, no-param-reassign */
const mainReducer = (state = initialState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case SET_USER_DATA:
      console.log(action.payload);
      newState = action.payload;
      break;

    case ADD_NEW_EMPTY_FRAME:
      newState = addNewEmptyFrame(newState, action.payload);
      break;

    case DELETE_FRAME:
      newState = deleteFrame(newState, action.payload);
      break;

    case SWITCH_FRAME:
      newState = switchFrame(newState, action.payload);
      break;

    case ADD_NEW_DRAG_ITEM:
      newState = addNewDragItem(newState, action.payload);
      break;

    case DELETE_DRAG_ITEM:
      newState = deleteDragItem(newState, action.payload);
      break;

    case CHANGE_DRAG_ITEM_POSITION:
      newState = changeDragItemPosition(newState, action.payload);
      break;

    case TOGGLE_ROW_SHOW:
      newState = toggleRowShow(newState, action.payload);
      break;

    case TOGGLE_UPDATE_DROPZONE:
      newState.updateDropzone = !newState.updateDropzone;
      break;

    case ADD_NEW_COLUMN:
      newState = addNewColumn(newState);
      break;

    case REMOVE_EMPTY_COLUMN:
      newState = removeEmptyColumn(newState);
      break;

    case TOGGLE_SIDEBAR:
      newState.isSidebarOpen = !state.isSidebarOpen;
      break;

    case TOGGLE_PREVIEW:
      newState.previewImage = !state.previewImage;
      break;

    case ADD_NESTED_DRAG_ITEM:
      newState = addNestedDragItem(state, action.payload);
      break;

    case CHANGE_POSITION_NESTED_DRAG_ITEM:
      newState = changePositionNestedDragItem(state, action.payload);
      break;

    case TOGGLE_SIDEBAR:
      newState.isSidebarOpen = !state.isSidebarOpen;

    default:
      break;
  }

  localStorage.setItem("user_data", JSON.stringify(newState));
  return newState;
};

export default mainReducer;