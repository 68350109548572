import React from "react";
import NavGroup from "./NavGroup";

export default function RightSidebar() {
  return (
    <div className="p-15 left-scroll">
      <NavGroup />
    </div>
  );
}
