import store from "redux/store.root";

const rowvalidity = [
  ["rotary", "key", "combi", "palate", "virtual"],
  ["rotary", "key", "combi", "palate", "virtual"],
  [
    "moniter",
    "combo_a",
    "combo_b",
    "fader_a",
    "fader_b",
    "fader_blank",
    "rotary",
    "key",
    "combi",
    "palate",
  ],
];

export const checkRowValidity = ({
  destinationRowId,
  destinationFrameId,
  sourceRowId,
  sourceFrameId,
  dropIndex,
  draggableId,
  withinFrame,
}) => {
  const state = store.getState();

  // Get destination Data
  const destinationData =
    state.main.frames[destinationFrameId].data[`row${destinationRowId}`].data;

  // Check column for Item can go into that particular row
  let checkColumn;

  // Restrict Drag Items according to the virtual Extension Length
  let length = destinationData.length;
  if (destinationData.includes("virtual")) {
    length = length * 2;
  }

  // Check the Items can be put in the column according to the Length
  if (withinFrame) {
    if (destinationRowId === sourceRowId) {
      checkColumn = true;
    } else {
      checkColumn = length < state.main.frames[destinationFrameId].columns;
    }
  } else {
    checkColumn = length < state.main.frames[destinationFrameId].columns;
  }

  // Get source Data
  const sourceData = withinFrame
    ? state.main.frames[sourceFrameId].data[`row${sourceRowId}`].data
    : [];

  // Get Dragitem Id
  draggableId = withinFrame ? sourceData[parseInt(draggableId)] : draggableId;

  const smallElements = ["rotary", "key", "combi", "palate"];
  const virtualElements = ["virtual"];

  // Global Rules For First And Second Row
  if ((destinationRowId == 1 || destinationRowId == 2) && length > 0) {
    if (
      destinationData.every((item) => smallElements.includes(item)) &&
      virtualElements.includes(draggableId)
    ) {
      return false;
    }

    if (
      destinationData.every((item) => virtualElements.includes(item)) &&
      smallElements.includes(draggableId)
    ) {
      return false;
    }
  }

  // Check if Second row contains virtual row then Do not Go For Extension Modules
  if (destinationRowId == 1 && smallElements.includes(draggableId)) {
    const row2Data = state.main.frames[destinationFrameId].data[`row${2}`].data;

    return (
      (row2Data.length === 0
        ? true
        : row2Data.reduce((acc, item) => {
            if (acc) {
              return item !== "virtual";
            }
          }, true)) && checkColumn
    );
  }

  // Check 2nd Row
  if (destinationRowId == 2 && virtualElements.includes(draggableId)) {
    const row1Data = state.main.frames[destinationFrameId].data[`row${1}`].data;

    return (
      row1Data.reduce((acc, item) => {
        if (acc) {
          return !smallElements.includes(item);
        }
      }, true) && checkColumn
    );
  }

  return (
    rowvalidity[destinationRowId - 1].includes(
      typeof draggableId === "string" ? draggableId : draggableId[0]
    ) && checkColumn
  );
};
