import { render } from "@testing-library/react";
import React from "react";
import { connect } from "react-redux";
import DropZoneFrameRow from "./DropZoneFrameRow";
import { TOGGLE_UPDATE_DROPZONE } from "redux/main/types.main";

class DropZoneFrame extends React.Component {
  componentDidUpdate = () => {
    if (this.props.updateDropzone) {
      this.forceUpdate();
      this.props.toggleUpdateDropzone();
    }
  };

  render() {
    const rowToShow = Object.keys(this.props.frame.data).reduce((acc, row) => {
      if (this.props.frame.data[row].show) {
        acc[row] = this.props.frame.data[row].show;
      }
      return acc;
    }, {});

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: 20,
          overflow: "auto",
        }}
      >
        <>
          {Object.keys(rowToShow).map((rowNumber) => {
            rowNumber = parseInt(rowNumber[rowNumber.length - 1]);

            return this.props.frame.data[`row${rowNumber}`].show ? (
              <DropZoneFrameRow
                columns={this.props.frame.columns}
                key={rowNumber}
                frameId={this.props.frameId}
                rowId={rowNumber}
              />
            ) : null;
          })}
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updateDropzone: state.main.updateDropzone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleUpdateDropzone: () => {
      dispatch({
        type: TOGGLE_UPDATE_DROPZONE,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropZoneFrame);
