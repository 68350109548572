import {
  checkRowValidity
} from "./dropzone/utils";
import store from "redux/store.root";
import {
  ADD_NESTED_DRAG_ITEM,
  ADD_NEW_DRAG_ITEM,
  CHANGE_DRAG_ITEM_POSITION,
  CHANGE_POSITION_NESTED_DRAG_ITEM,
} from "redux/main/types.main";

export const UpdateDragItem = ({
  destination,
  draggableId,
  source
}) => {
  console.log("indeside Platform", destination)


  const dropIndex = destination ? destination.index : null;
  const dragIndex = source.index;

  let sourceName, sourceFrameId, sourceRowId;

  if (source.droppableId.split("-")[0] === "frame") {
    [sourceName, sourceFrameId, sourceRowId] = source.droppableId.split("-");
  } else {
    sourceName = source.droppableId.split("-")[0];
  }

  let [
    destinationName,
    destinationFrameId,
    destinationRowId,
  ] = destination.droppableId.split("-");

  const withinFrame = sourceName === "frame" && destinationName === "frame";

  // Draggable assign
  draggableId = withinFrame ?
    draggableId.split("-")[2] :
    draggableId.split("-")[1];

  // Check the Drag Rules
  const check = checkRowValidity({
    destinationRowId,
    destinationFrameId,
    sourceRowId,
    sourceFrameId,
    dropIndex,
    draggableId,
    withinFrame,
  });
  // Add new DragItem For Non Nested
  if (check && sourceName !== "frame" && destinationName === "frame") {
    store.dispatch({
      type: ADD_NEW_DRAG_ITEM,
      payload: {
        destinationRowId,
        destinationFrameId,
        dropIndex,
        draggableId,
      },
    });
  }
  // Change Drag Item for Non Nested
  else if (withinFrame && check) {
    store.dispatch({
      type: CHANGE_DRAG_ITEM_POSITION,
      payload: {
        sourceRowId,
        destinationRowId,
        sourceFrameId,
        destinationFrameId,
        dropIndex,
        dragIndex,
      },
    });
  }
};

// Update Nested Drag Item
export const UpdateDragItemNested = ({
  destination,
  draggableId,
  source
}) => {
  console.log("inside Nested Droppable");
  // Check Nested and Apply According to Nested Droppables
  // Add new DragItem For Nested
  const destionationDroppableIdArray = destination.droppableId.split("-");
  const sourceDroppableIdArray = source.droppableId.split("-");

  // Condtion for adding New Drag Item
  if (
    destionationDroppableIdArray[0] === "frame" &&
    draggableId.split("-")[0] === "sidebar"
  ) {
    let [
      _,
      destinationFrameId,
      destinationRowId,
      destinationNestedId,
    ] = destionationDroppableIdArray.map((item) => parseInt(item));

    store.dispatch({
      type: ADD_NESTED_DRAG_ITEM,
      payload: {
        destinationFrameId,
        destinationRowId,
        destinationNestedId,
        destinationIndexId: destination.index,
        draggableId: draggableId.split("-")[1],
      },
    });

    return;
  }

  let _,
    __,
    destinationFrameId,
    destinationRowId,
    destinationNestedId = null,
    destinationIndexId,
    sourceFrameId,
    sourceRowId,
    sourceNestedId = null,
    sourceIndexId;

  // Within Frame Drag
  if (
    destionationDroppableIdArray[0] === "frame" &&
    sourceDroppableIdArray[0] === "frame"
  ) {
    // From other Rows To Nested
    if (
      sourceDroppableIdArray.length === 3 &&
      destionationDroppableIdArray.length === 4
    ) {
      [
        _,
        destinationFrameId,
        destinationRowId,
        destinationNestedId,
      ] = destionationDroppableIdArray.map((item) => parseInt(item));

      [__, sourceFrameId, sourceRowId] = sourceDroppableIdArray.map((item) =>
        parseInt(item)
      );
    }

    // From Nested To other Row within Frame
    else if (
      sourceDroppableIdArray.length === 4 &&
      destionationDroppableIdArray.length === 3
    ) {
      [
        _,
        destinationFrameId,
        destinationRowId,
      ] = destionationDroppableIdArray.map((item) => parseInt(item));

      [
        __,
        sourceFrameId,
        sourceRowId,
        sourceNestedId,
      ] = sourceDroppableIdArray.map((item) => parseInt(item));
    }

    // From Nested To Another Nested Group within Frame
    else if (
      sourceDroppableIdArray.length === 4 &&
      destionationDroppableIdArray.length === 4
    ) {
      [
        _,
        destinationFrameId,
        destinationRowId,
        destinationNestedId,
      ] = destionationDroppableIdArray.map((item) => parseInt(item));

      [
        __,
        sourceFrameId,
        sourceRowId,
        sourceNestedId,
      ] = sourceDroppableIdArray.map((item) => parseInt(item));
    }

    store.dispatch({
      type: CHANGE_POSITION_NESTED_DRAG_ITEM,
      payload: {
        destinationFrameId,
        destinationRowId,
        destinationNestedId,
        destinationIndexId: destination.index,
        sourceFrameId,
        sourceRowId,
        sourceNestedId,
        sourceIndexId: source.index,
      },
    });
  }
};