import React, { Component } from "react";
import "../home/Draggable.css";
import DropZone from "./dropzone/DropZone";

// import Example from "./icons/example";
import DraggableGroup from "./left-sidebar/DraggableGroup";
import sidebarData from "./sidebar.data";
import { DragDropContext } from "react-beautiful-dnd";
import {
  ADD_NEW_DRAG_ITEM,
  CHANGE_DRAG_ITEM_POSITION,
  DELETE_DRAG_ITEM,
  SET_USER_DATA,
  TOGGLE_SIDEBAR,
} from "redux/main/types.main";

import { connect } from "react-redux";
import RightSidebar from "./right-sidebar/RightSidebar";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { UpdateDragItem, UpdateDragItemNested } from "./dragEndUtil";

class PlatForm extends Component {
  onDragEnd = ({ destination, draggableId, source }) => {


    const falseDestination = sidebarData.map((group) => group.heading);

    // const dropIndex = destination ? destination.index : null;
    const dragIndex = source.index;

    if (!destination && source.droppableId.split("-")[0] === "frame") {
      this.props.deleteDragItem({
        rowId: source.droppableId.split("-")[2],
        dragIndex,
        nestedId:
          source.droppableId.split("-").length === 4
            ? source.droppableId.split("-")[3]
            : null,
      });
      return;
    } else if (
      !destination ||
      falseDestination.includes(destination.droppableId)
    ) {
      return;
    }
    if (
      destination.droppableId.split("-").length === 4 ||
      source.droppableId.split("-").length === 4
    ) {
      UpdateDragItemNested({ destination, draggableId, source });
      return;
    }
    UpdateDragItem({ destination, draggableId, source });
  };

  onDragStart = (result) => {
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    if (window.innerWidth <= 992) {
      this.setState({ isMobile: true });
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.width !== this.state.width) {
      if (this.state.width <= 992) {
        this.setState({ isMobile: true });
      } else {
        this.setState({ isMobile: false });
      }
    }
  }

  listMenu = () => {
    this.setState(
      {
        islistMenu: !this.state.islistMenu,
      },
      () => {
        console.log("hii", this.state.islistMenu);
      }
    );
  };

  state = {
    isMobile: false,
    width: window.innerWidth,
    islistMenu: false,
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };
  render() {
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
      >
        <ion-row>
          <ion-col size-md="3" class="scroll p-0 ios-mobile-left md hydrated">
            <div className=" left-scroll">
              <h5
                className="control-title"
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 50,
                }}
              >
                Modules
              </h5>
              <div style={{ marginBottom: 20 }}>
                {sidebarData.map((data, index) => (
                  <DraggableGroup key={index} {...data} />
                ))}
              </div>
            </div>
          </ion-col>
          <ion-col size-lg="7" class="iswidth" style={{ overflow: "auto" }}>
            <DropZone {...this.props} />
          </ion-col>
          {this.state.isMobile ? (
            this.props.isSidebarOpen && (
              <ion-col
                size-lg="2"
                size-md="3"
                class="border-left bg-gray p-0 ismobile"
              >
                <SwipeableDrawer
                  anchor="right"
                  open={this.props.isSidebarOpen}
                  onClose={this.props.toggleSidebar}
                >
                  <h5 className="control-title">Controls</h5>
                  <RightSidebar />
                </SwipeableDrawer>
              </ion-col>
            )
          ) : (
            <ion-col
              size-lg="2"
              size-md="3"
                class="border-left bg-gray p-0 ismobile left-scroll"

            >
              <h5 className="control-title">Controls</h5>
              <RightSidebar />
            </ion-col>
          )}
        </ion-row>
      </DragDropContext>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (payload) => {
      dispatch({ type: SET_USER_DATA, payload });
    },
    toggleSidebar: () => {
      dispatch({ type: TOGGLE_SIDEBAR });
    },
    deleteDragItem: (payload) => {
      dispatch({
        type: DELETE_DRAG_ITEM,
        payload,
      });
    },
    addNewDragItem: (payload) => {
      dispatch({
        type: ADD_NEW_DRAG_ITEM,
        payload,
      });
    },
    changeDragItemPosition: (payload) => {
      dispatch({
        type: CHANGE_DRAG_ITEM_POSITION,
        payload,
      });
    },
    deleteDragItem: (payload) => {
      dispatch({ type: DELETE_DRAG_ITEM, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    isSidebarOpen: state.main.isSidebarOpen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatForm);
